<script setup lang="ts">
import { Button, IconButton } from '@laam/ui/base';
import {
	PhCaretDown,
	PhHandbagSimple,
	PhMagnifyingGlass,
	PhUserCircle,
} from '@phosphor-icons/vue';
import { type OctaneHeaderData } from '@laam/cms-shared';
import NavDropdown from './NavDropdown.vue';
import CartButton from '../CartButton.vue';
import Logan from '../logan/Logan.vue';
import AccountSettings from '../logan/AccountSettings.vue';
import CurrencySelector from '../logan/CurrencySelector.vue';
import { useLoganStore } from '~/stores/logan';
import { navigateTo } from 'nuxt/app';

interface OctaneHeaderEmits {
	(e: 'search-clicked'): void;
}
interface OctaneHeaderProps {
	data: OctaneHeaderData;
}
defineProps<OctaneHeaderProps>();
const emit = defineEmits<OctaneHeaderEmits>();
const LoganStore = useLoganStore();
</script>

<template>
	<div
		class="max-w-[1280px] hidden border-b border-gray-100 lg:block px-8xl sticky top-0 z-50 bg-white mx-auto"
	>
		<!-- Top Bar -->
		<div class="flex py-3xl">
			<!-- Left Side -->
			<div class="flex items-center gap-md">
				<ClientOnly>
					<CurrencySelector v-slot="{ modelValue, methods }">
						<Button
							size="lg"
							variant="tertiary"
							class="font-semibold h-fit w-full !gap-sm py-md px-xl"
						>
							<div class="w-5xl h-5xl !max-w-[20px] min-w-[20px]">
								<NuxtImg
									:key="methods.getSelectedCountry(modelValue)!.flag"
									:src="methods.getSelectedCountry(modelValue)!.flag"
									alt="country flag"
									loading="lazy"
									width="10"
									class="w-full"
								>
								</NuxtImg>
							</div>
							<span class="text-left grow text-sm font-medium">
								{{ methods.getSelectedCountry(modelValue)!.name }}
							</span>
						</Button>
					</CurrencySelector>
				</ClientOnly>
				<!-- Search Button -->
				<NuxtLink :to="'/search'">
					<Button size="lg" variant="tertiary" @click="emit('search-clicked')">
						<PhMagnifyingGlass />
						Search
					</Button>
				</NuxtLink>
			</div>
			<!-- Middle -->
			<div class="flex justify-center items-center grow h-[80px]">
				<NuxtLink
					to="/"
					class="aspect-[224/44] h-[80px] logo flex items-center justify-center"
				>
					<NuxtImg
						v-if="data.logo.url"
						:src="data.logo.url"
						alt="Logo"
						class="h-full"
					/>
				</NuxtLink>
			</div>
			<!-- Right Side -->
			<div class="flex items-center gap-md">
				<!-- Cart Button -->
				<CartButton v-slot="{ itemCount }">
					<Button
						size="lg"
						variant="tertiary"
						class="hidden font-semibold pointer-events-none lg:flex h-fit"
					>
						<PhHandbagSimple />
						Bag ({{ itemCount }})
					</Button>
				</CartButton>
				<!-- Sign In Register Button -->
				<Logan v-if="!LoganStore.isSignedIn" :show-confirmation-text="false">
					<Button
						size="lg"
						variant="secondary"
						class="font-semibold border-0 pointer-events-none bg-gray-50 h-fit"
					>
						<PhUserCircle />
						Sign In / Register
					</Button>
				</Logan>
				<AccountSettings v-else>
					<Button
						size="lg"
						variant="secondary"
						class="font-semibold border-0 bg-gray-50 h-fit"
					>
						<PhUserCircle />
						My Account
					</Button>
				</AccountSettings>
			</div>
		</div>
		<!-- Navigation -->
		<div
			class="flex items-center py-md gap-3xl nav_list overflow-scroll"
			:class="{
				'justify-start': data.menubar.length > 8,
				'justify-center': data.menubar.length <= 8,
			}"
		>
			<template v-for="field in data.menubar" :key="field.href">
				<template v-if="field.children && field.children.length > 0">
					<Button
						variant="tertiary"
						size="md"
						class="gap-md !font-semibold"
						@click="navigateTo(field.href)"
					>
						{{ field.name }}
						<NavDropdown
							:data="
								field.children?.map((i) => ({
									label: i.name,
									href: i.href,
								})) || []
							"
							@menu-clicked="(href) => navigateTo(href)"
						>
							<IconButton
								variant="icon"
								class="!p-0"
								@click.stop="(e) => e.stopPropagation()"
								@click.prevent="(e) => e.stopPropagation()"
							>
								<PhCaretDown />
							</IconButton>
						</NavDropdown>
					</Button>
				</template>
				<template v-else>
					<Button
						variant="tertiary"
						size="md"
						class="gap-md !font-semibold"
						@click="navigateTo(field.href)"
					>
						{{ field.name }}
					</Button>
				</template>
			</template>
		</div>
	</div>
</template>
